import React from "react";
import { Helmet } from "react-helmet";
import contactImage from "../img/contact.jpg";

const Contact = () => {
  return (
    <main>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>Contact Us - Domini Mart</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://dominimart.ng/contact" />
      </Helmet>

      {/* Page Title */}
      <div
        className="page-title dark-background"
        data-aos="fade"
        style={{ backgroundImage: `url(${contactImage})` }}
      >
        <div className="container position-relative">
          <h1>Contact</h1>
        </div>
      </div>
      {/* End Page Title */}

      {/* Contact Section */}
      <section id="contact" className="contact section">
        <div className="container" data-aos="fade">
          <div className="row gy-5 gx-lg-5 mb-5 mt-2">
            <div className="col-lg-4">
              <div className="info">
                <h3>Get in touch</h3>
                <p>
                  Contact Us! We'd love to hear from you! Whether you have
                  questions about our services or want to discuss potential
                  partnerships, feel free to reach out.
                </p>

                <div className="info-item d-flex">
                  <i className="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4>Address:</h4>
                    <p>
                      6 DB Street, Ipaja,
                      <br />
                      Lagos, Nigeria
                    </p>
                  </div>
                </div>
                {/* End Info Item */}

                <div className="info-item d-flex">
                  <i className="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4>Email:</h4>
                    <p>info@dominimart.ng</p>
                  </div>
                </div>
                {/* End Info Item */}

                <div className="info-item d-flex">
                  <i className="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4>Phone:</h4>
                    <p>+234 803 055 4924</p>
                  </div>
                </div>
                {/* End Info Item */}
              </div>
            </div>

            <div className="col-lg-8">
              <form
                action="forms/contact.php" // Adjust or remove if you're handling this differently
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit">Send Us a Message</button>
                </div>
              </form>
            </div>
            {/* End Contact Form */}
          </div>
        </div>

        <div className="mb-1">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.3150744530394!2d3.2717685740464257!3d6.6077150221809156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b906191b248e5%3A0xa948be99881ea6f!2s6%20D%20B%20St%2C%20Ipaja%2C%20Lagos%20102213%2C%20Lagos!5e0!3m2!1sen!2sng!4v1724188199096!5m2!1sen!2sng"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        {/* End Google Maps */}
      </section>
      {/* End Contact Section */}
    </main>
  );
};

export default Contact;
