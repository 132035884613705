import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import logo from "./img/logo.JPG";
import footer from "./img/logo-footer.png";
import Home from "./pages/Index.jsx";
import About from "./pages/About.jsx";
import Services from "./pages/Services.jsx";
import Blog from "./pages/Blog.jsx";
import Contact from "./pages/Contact.jsx";

function App() {
  return (
    <>
      <Router>
        <header
          id="header"
          className="header d-flex align-items-center position-relative"
        >
          <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
            <a href="/" class="logo d-flex align-items-center">
              <img src={logo} alt="Dominimart" />
            </a>

            <nav id="navmenu" className="navmenu">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
              <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
            </nav>
          </div>
        </header>

        <div style={{ overflowX: "hidden" }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>

        <footer id="footer" class="dark-background">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="footer-info">
                    <img src={footer} alt="" />
                    <p>
                      6 DB Street, Ipaja,
                      <br />
                      Lagos, Nigeria
                      <br />
                      <br />
                      <strong>Phone:</strong> +234 803 055 4924
                      <br />
                      <strong>Email:</strong> info@dominimart.ng
                      <br />
                    </p>
                    <div class="social-links mt-3">
                      <a href="#" class="twitter">
                        <i class="bx bxl-twitter"></i>
                      </a>
                      <a href="#" class="facebook">
                        <i class="bx bxl-facebook"></i>
                      </a>
                      <a href="#" class="instagram">
                        <i class="bx bxl-instagram"></i>
                      </a>
                      <a href="#" class="linkedin">
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i> <a href="#">Home</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">About us</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Terms of service</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Privacy policy</a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Post-Harvest Processing</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Storage Solutions</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Logistics Management</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Daily Cafeteria Services</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Event Catering</a>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Our Newsletter</h4>
                  <p>Subscribe to our newsletter for latest posts</p>
                  <form action="" method="post">
                    <input type="email" name="email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>DominiMart</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
        </footer>

        <a
          href="#"
          id="scroll-top"
          class="scroll-top d-flex align-items-center justify-content-center"
        >
          <i class="bi bi-arrow-up-short"></i>
        </a>

        {/* <div id="preloader"></div> */}
      </Router>
    </>
  );
}

export default App;
