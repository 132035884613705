import React from "react";
import { Helmet } from "react-helmet";
import slider from "../img/hero.jpg";
import slider1 from "../img/hero-1.jpg";
import slider2 from "../img/hero-2.JPG";
import about from "../img/about.jpg";
import local from "../img/about-page.jpg";
import chilled from "../img/chilled.jpg";
import dry from "../img/dry.jpg";
import spices from "../img/local.jpg";
import rype from "../img/Rype Logo.png";
import gourmand from "../img/The_Gourmand_Logo.png";
import testimonial from "../img/testimonial.png";
import blog from "../img/sustainable.jpg";
import blog1 from "../img/tackling.jpg";
import blog2 from "../img/fresh.jpg";

const Index = () => {
  const posts = [
    {
      imgSrc: blog,
      date: "August 20",
      title: "The Benefits of Sustainable Farming Practices",
      author: "Taiwo Akande",
      category: "Agriculture",
      delay: 100,
    },
    {
      imgSrc: blog1,
      date: "August 20",
      title: "How Rype is Tackling Food Waste in Nigeria",
      author: "Taiwo Akande",
      category: "Agriculture",
      delay: 200,
    },
    {
      imgSrc: blog2,
      date: "August 20",
      title: "The Impact of Fresh Produce Quality on Restaurant Success",
      author: "Taiwo Akande",
      category: "Agriculture",
      delay: 300,
    },
  ];

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>
        Home - Domini Mart
        </title>
        <meta
          name="description"
          content=""
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://dominimart.ng/" />
      </Helmet>
      <main className="main">
        {/* Hero Section */}
        <section id="hero" className="hero section dark-background">
          <div
            id="hero-carousel"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
            data-bs-interval="5000"
          >
            <div className="carousel-item active">
              <img src={slider} alt="Hero" />
              <div className="carousel-container">
                <h2>Domini Mart Ventures</h2>
                <p>Transforming the Fresh Produce Supply Chain in Nigeria</p>
                <a href="/about" className="btn-get-started">
                  Learn More
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <img src={slider1} alt="Hero 1" />
              <div className="carousel-container">
                <h2>Domini Mart Ventures</h2>
                <p>Transforming the Fresh Produce Supply Chain in Nigeria</p>
                <a href="/about" className="btn-get-started">
                  Learn More
                </a>
              </div>
            </div>

            <div className="carousel-item">
              <img src={slider2} alt="Hero 2" />
              <div className="carousel-container">
                <h2>Domini Mart Ventures</h2>
                <p>Transforming the Fresh Produce Supply Chain in Nigeria</p>
                <a href="/about" className="btn-get-started">
                  Learn More
                </a>
              </div>
            </div>

            <a
              className="carousel-control-prev"
              href="#hero-carousel"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              ></span>
            </a>

            <a
              className="carousel-control-next"
              href="#hero-carousel"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              ></span>
            </a>

            <ol className="carousel-indicators"></ol>
          </div>
        </section>

        {/* About 3 Section */}
        <section id="about-3" className="about-3 section">
          <div className="container">
            <div className="row gy-4 justify-content-between align-items-center">
              <div className="col-lg-6 order-lg-2 position-relative">
                <img src={about} alt="About" className="img-fluid" />
                <a href="/" className="glightbox pulsating-play-btn">
                  <span className="play">
                    <i className="bi bi-play-fill"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-5 order-lg-1">
                <h2 className="content-title mb-4">About Us</h2>
                <p className="mb-4">
                  Welcome to Domini Mart Ventures. We are dedicated to the
                  aggregation, storage, processing, and last-mile delivery of
                  high-quality food raw materials to institutional consumers.
                  With Rype, our flagship tech solution, we aim to revolutionize
                  the fresh produce supply chain in Nigeria.
                </p>
                <ul className="list-unstyled list-check">
                  <li>Improved Post-Harvest Processing</li>
                  <li>Efficient Logistics and Distribution</li>
                  <li>Quality Assurance and Control</li>
                  <li>Sustainable and Profitable Practices</li>
                </ul>
                <p>
                  <a href="/" className="btn-cta">
                    Get in touch
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Products Section */}
        <section id="products" className="products dark-background">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Our Products</h2>
              <p>Revolutionizing Fresh Produce Supply</p>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-3">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item">
                    <a
                      className="nav-link active show"
                      data-bs-toggle="tab"
                      href="#tab-1"
                    >
                      Local Fruits and Vegetables
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                      Chilled Foods
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                      Dry Goods and Groceries
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#tab-4">
                      Local Spices and Condiments
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="tab-content">
                  <div className="tab-pane active show" id="tab-1">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Local Fruits and Vegetables</h3>
                        <p className="fst-italic">
                          Fresh and domestically sourced
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={local}
                          alt="Local Fruits"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-2">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Chilled Foods</h3>
                        <p className="fst-italic">
                          High-quality imported sausages, deli meats, butter,
                          cheese, and milk
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={chilled}
                          alt="Chilled Foods"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-3">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Dry Goods and Groceries</h3>
                        <p className="fst-italic">
                          A wide array of imported and local processed foods
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img src={dry} alt="Dry Goods" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-4">
                    <div className="row">
                      <div className="col-lg-8 details order-2 order-lg-1">
                        <h3>Local Spices and Condiments</h3>
                        <p className="fst-italic">
                          Essential ingredients for traditional dishes
                        </p>
                      </div>
                      <div className="col-lg-4 text-center order-1 order-lg-2">
                        <img
                          src={spices}
                          alt="Local Spices"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <section id="services" class="services">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Services</h2>
              <p>The services we provide</p>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6" data-aos="fade-up">
                <div class="icon-box">
                  <div class="icon">
                    <img src={rype} alt="" />
                  </div>
                  <h4 class="title">
                    <a href="/">
                      Revolutionizing the Fresh Produce Supply Chain
                    </a>
                  </h4>
                  <p class="description">
                    Rype is designed to streamline the supply chain from farm to
                    table, ensuring consistent quality and reducing waste.
                  </p>
                </div>
              </div>
              <div
                class="col-lg-6 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div class="icon-box">
                  <div class="icon">
                    <img src={gourmand} alt="" />
                  </div>
                  <h4 class="title">
                    <a href="/">Premium Dining Experience</a>
                  </h4>
                  <p class="description">
                    Located at the Nigerian University of Technology and
                    Management, The Gourmand offers a diverse menu of healthy
                    and delicious meals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" class="testimonials light-background">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>Testimonials</h2>
              <p>What they're saying about us</p>
            </div>

            <div
              class="testimonials-slider swiper-container"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="testimonial-item">
                    <p>
                      <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                      We've seen significant improvements in quality and
                      consistency since partnering with Domini Mart.
                      <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <img src={testimonial} class="testimonial-img" alt="" />
                    <h3>Lagos Continental Hotel</h3>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </section>

        {/* Recent Posts Section */}
        <section id="recent-posts" className="recent-posts section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Recent Posts</h2>
          </div>

          <div className="container">
            <div className="row gy-5">
              {posts.map((post, index) => (
                <div className="col-xl-4 col-md-6" key={index}>
                  <div
                    className="post-item position-relative h-100"
                    data-aos="fade-up"
                    data-aos-delay={post.delay}
                  >
                    <div className="post-img position-relative overflow-hidden">
                      <img src={post.imgSrc} className="img-fluid" alt="" />
                      <span className="post-date">{post.date}</span>
                    </div>

                    <div className="post-content d-flex flex-column">
                      <h3 className="post-title">{post.title}</h3>
                      <div className="meta d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-person"></i>{" "}
                          <span className="ps-2">{post.author}</span>
                        </div>
                        <span className="px-3 text-black-50">/</span>
                        <div className="d-flex align-items-center">
                          <i className="bi bi-folder2"></i>{" "}
                          <span className="ps-2">{post.category}</span>
                        </div>
                      </div>

                      <hr />

                      <a href="#" className="readmore stretched-link">
                        <span>Read More</span>
                        <i className="bi bi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Index;
