import React from "react";
import { Helmet } from "react-helmet";
import blogImage from "../img/blog.jpg";
import sustainableImage from "../img/sustainable.jpg";
import tacklingImage from "../img/tackling.jpg";
import freshImage from "../img/fresh.jpg";

const Blog = () => {
  const posts = [
    {
      imgSrc: sustainableImage,
      date: "August 20",
      title: "The Benefits of Sustainable Farming Practices",
      author: "Taiwo Akande",
      category: "Agriculture",
    },
    {
      imgSrc: tacklingImage,
      date: "August 20",
      title: "How Rype is Tackling Food Waste in Nigeria",
      author: "Taiwo Akande",
      category: "Agriculture",
    },
    {
      imgSrc: freshImage,
      date: "August 20",
      title: "The Impact of Fresh Produce Quality on Restaurant Success",
      author: "Taiwo Akande",
      category: "Agriculture",
    },
  ];

  return (
    <main>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>Blog - Domini Mart</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://dominimart.ng/blog" />
      </Helmet>

      {/* Page Title */}
      <div
        className="page-title dark-background"
        data-aos="fade"
        style={{ backgroundImage: `url(${blogImage})` }}
      >
        <div className="container position-relative">
          <h1>Blog</h1>
        </div>
      </div>
      {/* End Page Title */}

      {/* Blog Posts Section */}
      <section id="blog-posts-2" className="blog-posts-2 section">
        <div className="container">
          <div className="row gy-4">
            {posts.map((post, index) => (
              <div key={index} className="col-lg-4">
                <article className="position-relative h-100">
                  <div className="post-img position-relative overflow-hidden">
                    <img
                      src={post.imgSrc}
                      className="img-fluid"
                      alt={post.title}
                    />
                  </div>
                  <div className="meta d-flex align-items-end">
                    <span className="post-date">
                      <span>{post.date.split(" ")[0]}</span>
                      {post.date.split(" ")[1]}
                    </span>
                    <div className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <span className="ps-2">{post.author}</span>
                    </div>
                    <span className="px-3 text-black-50">/</span>
                    <div className="d-flex align-items-center">
                      <i className="bi bi-folder2"></i>{" "}
                      <span className="ps-2">{post.category}</span>
                    </div>
                  </div>
                  <div className="post-content d-flex flex-column">
                    <h3 className="post-title">{post.title}</h3>
                    <a href="#" className="readmore stretched-link">
                      <span>Read More</span>
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Blog Posts Section */}
    </main>
  );
};

export default Blog;
