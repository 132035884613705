import React from "react";
import { Helmet } from "react-helmet";
import services from "../img/services.jpg";
import rype from "../img/rype.jpg";
import gourmand from "../img/gourmand-1.jpg";

const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>Services - Domini Mart</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://dominimart.ng/services" />
      </Helmet>
      <main>
        {/* Page Title */}
        <div
          className="page-title dark-background"
          data-aos="fade"
          style={{ backgroundImage: `url(${services})` }}
        >
          <div className="container position-relative">
            <h1>Our Services</h1>
          </div>
        </div>
        {/* End Page Title */}

        {/* About Section */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img src={rype} alt="Rype" />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <h3>Rype: Revolutionizing the Fresh Produce Supply Chain</h3>
                <p>
                  Rype is designed to streamline the supply chain from farm to
                  table, ensuring consistent quality and reducing waste.
                </p>
                <p>Our services include:</p>
                <ul className="list-unstyled list-check">
                  <li>
                    Post-Harvest Processing: Advanced techniques to maintain
                    freshness.
                  </li>
                  <li>
                    Storage Solutions: State-of-the-art facilities for optimal
                    storage.
                  </li>
                  <li>
                    Logistics Management: Efficient distribution to minimize
                    delays.
                  </li>
                  <li>
                    Quality Control: Rigorous checks to guarantee the best
                    produce.
                  </li>
                </ul>
                <p>
                  For inquiries, please contact us <a href="/about">here</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}

        {/* About Section */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img src={gourmand} alt="The Gourmand" />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 content">
                <h3>The Gourmand: Premium Dining Experience</h3>
                <p>
                  Located at the Nigerian University of Technology and
                  Management, The Gourmand offers a diverse menu of healthy and
                  delicious meals.
                </p>
                <ul className="list-unstyled list-check">
                  <li>
                    Daily Cafeteria Services: Freshly prepared meals for
                    students and staff.
                  </li>
                  <li>
                    Event Catering: Customized menus for events and functions.
                  </li>
                </ul>
                <p>
                  For inquiries, please contact us <a href="/about">here</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
      </main>
    </>
  );
};

export default ServicesPage;
