import React from "react";
import { Helmet } from "react-helmet";
import aboutPageImg from "../img/about-page.jpg";
import aboutImg from "../img/about.jpg";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />

        <title>About - Domini Mart</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://dominimart.ng/about" />
      </Helmet>

      <main className="main">
        {/* Page Title */}
        <div
          className="page-title dark-background"
          data-aos="fade"
          style={{ backgroundImage: `url(${aboutPageImg})` }}
        >
          <div className="container position-relative">
            <h1>About</h1>
          </div>
        </div>
        {/* End Page Title */}

        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div
                className="col-lg-6 order-1 order-lg-2"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div className="about-img">
                  <img src={aboutImg} alt="About us" />
                </div>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <h3>Domini Mart Ventures</h3>
                <p>
                  Established in 2013, Domini Mart Ventures is a food
                  procurement and logistics company dedicated to the
                  aggregation, storage, processing, and last-mile delivery of
                  high-quality food raw materials to institutional consumers.
                  Our product portfolio includes fresh fruits, vegetables,
                  chilled foods, dry goods, and local spices and condiments.
                </p>

                <div className="icon-box" data-aos="fade-up">
                  <div className="icon">
                    <i className="fa fa-solid fa-bullseye"></i>
                  </div>
                  <h4 className="title">
                    <a href="#">Our Mission</a>
                  </h4>
                  <p className="description">
                    To revolutionize the fresh produce supply chain in Nigeria
                    by leveraging technology and innovation to ensure consistent
                    quality, reduce waste, and create a sustainable and
                    profitable ecosystem for all stakeholders.
                  </p>
                </div>

                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i className="fa fa-solid fa-eye"></i>
                  </div>
                  <h4 className="title">
                    <a href="#">Our Vision</a>
                  </h4>
                  <p className="description">
                    To be the leading provider of fresh produce solutions in
                    Nigeria, recognized for our innovation, quality, and
                    commitment to sustainability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
      </main>
    </>
  );
};

export default About;
